import React from 'react';

import { Header } from '../Header/Header';
import { Brand } from '../Brand/Brand';
import { Countdown } from '../Countdown/Countdown';
import { Event } from '../Event/Event';
import { UpcommingDate } from '../UpcommingDate/UcommingDate';

export const App = () => {
  return (
    <>
      <Header />
      <Brand />
      <Countdown />
      <Event />
      <UpcommingDate prefix="Upcoming Date" date="5th of June 2025" />
    </>
  );
};
